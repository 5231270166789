import {
    TypedStartListening,
    createListenerMiddleware,
} from "@reduxjs/toolkit";
import { getStore, RootState, AppDispatch } from "app/store";
import { appDispatch, getUniqueId } from "./util";
import { envApi } from "services/env";
import { appServiceApi } from "services/appService";
import {
    tasksSlice,
    removeDiaryEntriesByDate,
    removeTaskDiaryEntriesByDate,
    DEFAULT_DIARY_CUTOFF,
} from "features/tasks/tasksSlice";
import { venueSlice } from "features/venue/venueSlice";
import { suppliersSlice } from "features/suppliers/suppliersSlice";
import {
    appSlice,
    setActiveVenueId,
    addDiaryEntriesToQueue,
    selectDiaryEntryQueue,
    removeDiaryEntriesFromQueue,
    removeDiaryFileFromQueue,
    selectDiaryFilesQueue,
    selectReloadingTasks,
    selectReloadingSuppliers,
    selectReloadingStaff,
    DEFAULT_SCREEN,
    setActiveScreen,
    setUrlPath,
    selectActiveVenueId,
    setServerErrors,
    setReloadingPlan,
    selectServerErrors,
} from "./appSlice";
import { selectEnv } from "features/environment/envSlice";
import { selectUnauthorised } from "features/loginToken/loginTokenSlice";
import { REHYDRATE } from "redux-persist";
import envListeners from "features/environment/listeners";
import taskListeners from "features/tasks/listeners";
import venueListeners from "features/venue/listeners";
import staffListeners from "features/staff/listeners";
import suppliersListeners from "features/suppliers/listeners";
import loginTokenListeners from "features/loginToken/listeners";
import dataRedundancyListeners from "features/dataRedundancy/dataRedundancyListeners";
import {
    loginTokenSlice,
    setLoginError,
    selectIsLoggedIn,
    setShowLogin,
} from "features/loginToken/loginTokenSlice";
import { authServiceApi } from "services/authService";
import { detect } from "detect-browser";
import { reportException } from "reportHandler";

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const ONE_MINUTE = 60000;
const FIVE_MINUTES = 300000;
const TWENTY_FOUR_HOURS = 60000 * 60 * 24;
const DEFAULT_REFRESH = FIVE_MINUTES;

async function initialiseApp() {
    const store = getStore();
    const state = store.getState();

    store.dispatch(appServiceApi.util.resetApiState());
    store.dispatch(authServiceApi.util.resetApiState());

    store.dispatch(
        envApi.endpoints.getEnv.initiate(void 0, {
            subscriptionOptions: { pollingInterval: ONE_MINUTE },
        })
    );

    store.dispatch(envApi.endpoints.getTaskViewData.initiate());

    setupDiaryCleanup();
    setupDiaryQueue();
    setupDiaryFilesQueue();
    setupUnauthorised();
    setupExceptionHandling();

    listenToHistory();

    // Reset server errors as the app will try and reload data
    // if a token is present and we want to show progress to the user.
    store.dispatch(setServerErrors({}));

    const loggedIn = selectIsLoggedIn(state);
    if (!loggedIn) {
        // reset login error when reloading as it is no longer useful
        store.dispatch(setLoginError());
        store.dispatch(setShowLogin(false));
        return;
    }

    store.dispatch(setReloadingPlan(false));

    loadVenueData();
}

let _diaryCleanupInterval;
function setupDiaryCleanup() {
    setTimeout(() => {
        cleanupDiaryEntries();
        _diaryCleanupInterval = setInterval(() => {
            cleanupDiaryEntries();
        }, TWENTY_FOUR_HOURS);
    });
}

function getIsLoggedIn(): boolean {
    const store = getStore();
    const state = store.getState();
    return selectIsLoggedIn(state);
}

let _diaryQueueInterval;
function setupDiaryQueue() {
    setTimeout(() => {
        uploadDiaryQueue();
        _diaryQueueInterval = setInterval(() => {
            if (getIsLoggedIn()) uploadDiaryQueue();
        }, ONE_MINUTE);
    });
}

let _diaryFilesQueueInterval;
function setupDiaryFilesQueue() {
    setTimeout(() => {
        uploadDiaryFilesQueue();
        _diaryFilesQueueInterval = setInterval(() => {
            if (getIsLoggedIn()) uploadDiaryFilesQueue();
        }, ONE_MINUTE);
    });
}

const _unauthorisedCheckTime = TWENTY_FOUR_HOURS;
let _unauthorisedCheckStart: Date | undefined;
let _unauthorisedInterval: ReturnType<typeof setInterval>;
function setupUnauthorised() {
    _unauthorisedInterval = setInterval(() => {
        // Stop checking if the user has been unauthorised for
        // longer than _unauthorisedIntervalTime
        if (
            _unauthorisedCheckStart &&
            new Date().getTime() - _unauthorisedCheckStart.getTime() >
                _unauthorisedCheckTime
        ) {
            return;
        }
        const state = getStore().getState();

        const unauthorised = selectUnauthorised(state);
        if (!unauthorised) {
            _unauthorisedCheckStart = undefined;
            return;
        }

        const activeVenueId = selectActiveVenueId(state);
        if (!activeVenueId) return;

        const serverErrors = selectServerErrors(state);
        if (!serverErrors) return;

        const endpoints = Object.keys(serverErrors);
        if (endpoints.length === 0) return;

        if (!_unauthorisedCheckStart) {
            _unauthorisedCheckStart = new Date();
        }

        switch (endpoints[0]) {
            case "venue":
                fetchVenueData(activeVenueId);
                break;
            case "tasks":
                fetchTasks(activeVenueId);
                break;
            case "staff":
                fetchStaff(activeVenueId);
                break;
            case "suppliers":
                fetchSuppliers(activeVenueId);
                break;
            case "categories":
                fetchCategories(activeVenueId);
                break;
        }
    }, ONE_MINUTE);
}

// History events
const eventPopstate = "popstate";
const eventPushState = "pushState";
const eventReplaceState = "replaceState";
const eventHashchange = "hashchange";
export const events = [
    eventPopstate,
    eventPushState,
    eventReplaceState,
    eventHashchange,
];

function listenToHistory() {
    appDispatch(setUrlPath(window.location.pathname));
    for (const event of events) {
        window.addEventListener(event, (e: Event) => {
            appDispatch(setUrlPath(window.location.pathname));
        });
    }
}

// Exception handling
function setupExceptionHandling() {
    window.addEventListener("error", (event) => {
        console.error("Error event", event);
        reportException("Exception Handler", event.error);
    });
}

function uploadDiaryQueue() {
    const state = getStore().getState();
    const diaries = selectDiaryEntryQueue(state);
    if (diaries.length > 0) {
        getStore().dispatch(
            appServiceApi.endpoints.sendDiaries.initiate(diaries)
        );
    }
}

function cleanupDiaryEntries() {
    const dispatch = getStore().dispatch;

    dispatch(removeDiaryEntriesByDate(DEFAULT_DIARY_CUTOFF));
    dispatch(removeTaskDiaryEntriesByDate(DEFAULT_DIARY_CUTOFF));
}

let _uploadingFile = false;
function uploadDiaryFilesQueue() {
    const state = getStore().getState();
    const files = selectDiaryFilesQueue(state);
    if (files.length > 0 && !_uploadingFile) {
        _uploadingFile = true;
        const file = files[0];
        getStore().dispatch(appServiceApi.endpoints.sendFile.initiate(file));
    }
}

let _venueInterval: ReturnType<typeof setInterval>;
let _tasksInterval: ReturnType<typeof setInterval>;
let _categoriesInterval: ReturnType<typeof setInterval>;
let _staffInterval: ReturnType<typeof setInterval>;
let _suppliersInterval: ReturnType<typeof setInterval>;
export function loadVenueData() {
    const state = getStore().getState();
    const env = selectEnv(state);
    const venueRefresh = env?.venueRefresh || DEFAULT_REFRESH;
    const taskRefresh = env?.tasksRefresh || DEFAULT_REFRESH;
    const supplierRefresh = env?.suppliersRefresh || DEFAULT_REFRESH;
    const staffRefresh = env?.staffRefresh || DEFAULT_REFRESH;
    const activeVenueId = selectActiveVenueId(state);

    if (!activeVenueId) return;

    setTimeout(() => {
        fetchVenueData(activeVenueId);
        _venueInterval = setInterval(() => {
            if (shouldRefresh()) fetchVenueData(activeVenueId);
        }, venueRefresh);

        fetchTasks(activeVenueId);
        _tasksInterval = setInterval(() => {
            if (shouldRefresh(selectReloadingTasks)) fetchTasks(activeVenueId);
        }, taskRefresh);

        fetchCategories(activeVenueId);
        _categoriesInterval = setInterval(() => {
            if (shouldRefresh(selectReloadingTasks))
                fetchCategories(activeVenueId);
        }, taskRefresh);

        fetchSuppliers(activeVenueId);
        _suppliersInterval = setInterval(() => {
            if (shouldRefresh(selectReloadingSuppliers))
                fetchSuppliers(activeVenueId);
        }, supplierRefresh);

        fetchStaff(activeVenueId);
        _staffInterval = setInterval(() => {
            if (shouldRefresh(selectReloadingStaff)) fetchStaff(activeVenueId);
        }, staffRefresh);
    });
}

export function cancelDataReloading() {
    clearInterval(_venueInterval);
    clearInterval(_tasksInterval);
    clearInterval(_categoriesInterval);
    clearInterval(_staffInterval);
    clearInterval(_suppliersInterval);
}

function getBrowserData(): string {
    const browser_detect = detect();
    let browser = "";
    if (browser_detect) {
        browser =
            browser_detect.name +
            ":" +
            browser_detect.version +
            ":" +
            browser_detect.os;
    }
    return browser;
}

function fetchVenueData(venueId: number) {
    const store = getStore();
    const uniqueId = getUniqueId();
    const browser = getBrowserData();
    store.dispatch(
        appServiceApi.endpoints.getVenueData.initiate(
            {
                venueId,
                uniqueId,
                browser,
            },
            {
                forceRefetch: true,
            }
        )
    );
}

function fetchTasks(venueId: number) {
    getStore().dispatch(
        appServiceApi.endpoints.getTasks.initiate(venueId, {
            forceRefetch: true,
        })
    );
}

function fetchCategories(venueId: number) {
    getStore().dispatch(
        appServiceApi.endpoints.getCategories.initiate(venueId, {
            forceRefetch: true,
        })
    );
}

function fetchSuppliers(venueId: number) {
    getStore().dispatch(
        appServiceApi.endpoints.getSuppliers.initiate(venueId, {
            forceRefetch: true,
        })
    );
}

function fetchStaff(venueId: number) {
    getStore().dispatch(
        appServiceApi.endpoints.getStaff.initiate(venueId, {
            forceRefetch: true,
        })
    );
}

function shouldRefresh(
    reloadingSelector?: (state: RootState) => boolean
): boolean {
    const state = getStore().getState();
    const loggedIn = selectIsLoggedIn(state);

    if (!loggedIn) {
        return false;
    } else if (reloadingSelector) {
        return !reloadingSelector(state);
    } else {
        return true;
    }
}

export const startSendDiariesListening = (
    startListening: AppStartListening
) => {
    startListening({
        matcher: appServiceApi.endpoints.sendDiaries.matchFulfilled,
        effect: (action, listenerApi) => {
            const diaries = action.payload;
            getStore().dispatch(removeDiaryEntriesFromQueue(diaries));
        },
    });
};

export const startDiariesAddedListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: tasksSlice.actions.addDiaryEntries,
        effect: (action, listenerApi) => {
            getStore().dispatch(addDiaryEntriesToQueue(action.payload));
        },
    });
};

export const startSendFilesListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.sendFile.matchFulfilled,
        effect: (action, listenerApi) => {
            _uploadingFile = false;
            const file = action.payload;
            getStore().dispatch(removeDiaryFileFromQueue(file));
            uploadDiaryFilesQueue();
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.sendFile.matchRejected,
        effect: (action, listenerApi) => {
            _uploadingFile = false;
        },
    });
};

export const startUserVenuesListening = (startListening: AppStartListening) => {
    startListening({
        actionCreator: loginTokenSlice.actions.setUserVenues,
        effect: (action, listenerApi) => {
            const venues = action.payload;
            if (!venues || venues.length === 0) {
                listenerApi.dispatch(setActiveVenueId());
            } else {
                let activeVenue = venues[0];
                listenerApi.dispatch(setActiveVenueId(activeVenue.id));
                getStore().dispatch(setActiveScreen(DEFAULT_SCREEN));
                loadVenueData();
            }
        },
    });
};

export const startRehydrateListening = (startListening: AppStartListening) => {
    startListening({
        type: REHYDRATE,
        effect: async (action, listenerApi) => {
            initialiseApp();
        },
    });
};

export const startResetStateListening = (startListening: AppStartListening) => {
    startListening({
        actionCreator: appSlice.actions.resetState,
        effect: (action, listenerApi) => {
            cancelDataReloading();
            const store = getStore();
            store.dispatch(venueSlice.actions.resetState());
            store.dispatch(tasksSlice.actions.resetState());
            store.dispatch(suppliersSlice.actions.resetState());
            store.dispatch(loginTokenSlice.actions.resetState());
        },
    });
};

let listeners = [
    startDiariesAddedListening,
    startSendFilesListening,
    startSendDiariesListening,
    startRehydrateListening,
    startResetStateListening,
    startUserVenuesListening,
];
listeners = listeners.concat(
    envListeners,
    taskListeners,
    venueListeners,
    staffListeners,
    suppliersListeners,
    loginTokenListeners,
    dataRedundancyListeners
);

export const listenerMiddleware = createListenerMiddleware();

export const addListeners = (startListening: AppStartListening) => {
    for (let listener of listeners) {
        listener(startListening);
    }
};

addListeners(listenerMiddleware.startListening as AppStartListening);
