import { PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { getStore, RootState } from "./store";
import {
    selectUniqueId,
    setUniqueId,
    selectBranchName,
} from "features/environment/envSlice";
import { generateUniqueId } from "features/environment/util";
import { version } from "VERSION";

export function appDispatch<T>(action: PayloadAction<T>) {
    getStore().dispatch(action);
}

export function appSelect<T>(selector: (state: RootState) => T): T {
    const state = getStore().getState();

    return selector(state);
}

export function appSelectArg<T, S>(
    selector: (state: RootState, arg: S) => T,
    arg: S
): T {
    const state = getStore().getState();

    return selector(state, arg);
}

export function getUniqueId(): string {
    let uniqueId = appSelect(selectUniqueId);
    if (!uniqueId) {
        uniqueId = generateUniqueId();
        appDispatch(setUniqueId(uniqueId));
    }
    return uniqueId;
}

export function getBuildVersion(): string {
    let buildVersion = `${version}`;
    const branchName = appSelect(selectBranchName);
    if (branchName) {
        buildVersion += `-${branchName}`;
    }

    return buildVersion;
}

export function viewportHeightAvailable(
    element: HTMLElement
): number | undefined {
    let viewportHeight = window.visualViewport?.height;
    let availableHeight;
    if (viewportHeight) {
        availableHeight = viewportHeight - element.offsetTop;
    }
    return availableHeight;
}

export function sqlDatetime(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm:ss");
}

export function isTestServer(): boolean {
    return window.location.hostname.includes("test");
}

export function isTesting(): boolean {
    let isTesting = false;
    try {
        isTesting = process.env.JEST_WORKER_ID !== undefined;
    } catch (e) {}
    return isTesting;
}

// Dumb pluralisation function which works for our current needs.
export function pluralise(string: string, count: number): string {
    if (count === 1) {
        return string;
    } else {
        return string + "s";
    }
}
